// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '../views/template/apps/todo/store'
import chat from '../views/template/apps/chat/store'
import email from '../views/template/apps/email/store'
import kanban from '../views/template/apps/kanban/store'
import agenda from '../views/agenda/store'
import ecommerce from '../views/template/apps/ecommerce/store'
import dataTables from '../views/template/tables/data-tables/store'
import permissions from '../views/template/apps/roles-permissions/store'
import business_units from '../views/settings/BusinessUnits/store'
import bank_accounts from '../views/expenses/accounts/store'
import users from '../views/settings/User/store'
import customers from '../views/customers/store'
import dependente from '../views/dependentes/store/index'
import dependenteCliente from '../views/new-contract/store/index'
import funnel from '../views/settings/Personalize/Funil/store'
import roles from '../views/settings/Roles/store'
import party from '../views/settings/Personalize/Party/store'
import departments from '../views/settings/Personalize/Departments/store'
import lead from '../views/settings/Personalize/Traffic/store'
import contracts from '../views/contracts/store'
import packages from '../views/packages/store'
import custom_fields from '../views/settings/CustomFields/store'
import orcamento from '../views/sales-funnel/store/index'
import customer_area from '../views/customer_area/store'
import invoices from '../views/invoices/store'
import templates from '../views/templates/store'
import publicConfigs from '../views/public/store'
import tarefaOrcamento from '../views/sales-funnel/store/tarefaStore'
import option from '../views/settings/WaSettings/store'
import proposals from '../views/sales-funnel/store/ProposalStore'
import fornecedores from '../views/expenses/suppliers/store'
import team_members from '../views/expenses/collaborators/store'
import costCenters from '../views/expenses/personalize/store'
import expense_categories from '../views/expenses/personalize/store/storeCategorias'
import expense_sub_categories from '../views/expenses/personalize/store/storeSubcategorias'
import tipo_tarefas from '../views/settings/Personalize/Tasks/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  agenda,
  ecommerce,
  dataTables,
  permissions,
  business_units,
  bank_accounts,
  roles,
  customers,
  funnel,
  party,
  departments,
  lead,
  contracts,
  packages,
  custom_fields,
  dependente,
  orcamento,
  customer_area,
  invoices,
  templates,
  publicConfigs,
  dependenteCliente,
  tarefaOrcamento,
  option,
  proposals,
  fornecedores,
  team_members,
  costCenters,
  expense_categories,
  expense_sub_categories,
  tipo_tarefas
}

export default rootReducer
