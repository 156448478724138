import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'bank_accounts'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {
  const response = await axios.get(`${endpoint}`, { params })
  return response.data
})


export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data
})

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.post(endpoint, item)
    return response.data
  } catch (e) {
    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }
  // return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})

export const balanceAdjustment = createAsyncThunk(`${endpoint}/balanceAdjustment`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})

export const archive = createAsyncThunk(`${endpoint}/archive`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})

export const unarchive = createAsyncThunk(`${endpoint}/unarchive`, async (item, { dispatch }) => {
  await axios.put(`${endpoint}/${item.id}`, item)
  return item
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (item, { dispatch }) => {
  await axios.delete(`${endpoint}/${item.id}`)
  await dispatch(getAll({ id: item.fk_organizacao }))
  return item
})


export const bankAccountsSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    status: 'READY',
    total_balance: '',
    banks: [],
    params: {},
    selected: null,
    error: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.banks = action.payload.banks
        state.params = action.payload.params
        state.total_balance = action.payload.total_balance
        state.status = 'SUCCESS'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Salvo com Sucesso!'
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.error = action.payload || 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS'
      })
      .addCase(update.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'UPDATED_ERROR'
      })
      .addCase(balanceAdjustment.fulfilled, (state, action) => {
        state.status = 'ADJUSTMENT_SUCCESS'
      })
      .addCase(balanceAdjustment.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ADJUSTMENT_ERROR'
      })
      .addCase(archive.fulfilled, (state, action) => {
        state.status = 'ARCHIVED_SUCCESS'
      })
      .addCase(archive.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'ARCHIVED_ERROR'
      })
      .addCase(unarchive.fulfilled, (state, action) => {
        state.status = 'UNARCHIVED_SUCCESS'
      })
      .addCase(unarchive.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'UNARCHIVED_ERROR'
      })
      .addCase(remove.rejected, (state, action) => {
        state.selected = action.error
        state.status = 'DELETE_ERROR'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'DELETE_SUCCESS'
      })
  }
})

export default bankAccountsSlice.reducer