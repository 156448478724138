import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getAll = createAsyncThunk(`cost-centers`, async params => {
    const response = await axios.get(`/cost-centers`, { params })
    return response.data
})

export const get = createAsyncThunk(`cost-centers/get`, async (id) => {
    const response = await axios.get(`cost-centers/${id}`, { id })
    return response.data
})

export const add = createAsyncThunk(`cost-centers/add`, async (item, { rejectWithValue }) => {

    try {
        const response = await axios.post('/cost-centers', item)
        return response.data.data
    } catch (e) {
        if (!e.response) {
            throw e
        }
        return rejectWithValue(e.response.data)
    }
})

export const update = createAsyncThunk(`cost-centers/update`, async (item) => {
    const response = await axios.put(`cost-centers/${item.id}`, item)
    return response.data
})

export const archive = createAsyncThunk(`cost-centers/archive`, async (item, { dispatch }) => {
    await axios.put(`cost-centers/${item.id}`, item)
    return item
})

export const unarchive = createAsyncThunk(`cost-centers/unarchive`, async (item, { dispatch }) => {
    await axios.put(`cost-centers/${item.id}`, item)
    return item
})

export const costCenterSlice = createSlice({
    name: 'costCenters',
    initialState: {
        data: [],
        status: 'READY',
        total: 0,
        params: {},
        selected: {},
        error: ''
    },
    reducers: {
        clearSelected: (state, payload) => {
            state.selected = {}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.status = 'SUCCESS'
            })
            .addCase(getAll.pending, (state) => {
                state.data = []
                state.status = 'PENDING'
            })
            .addCase(getAll.rejected, (state, action) => {
                state.data = []
                state.error = action.payload || 'Não foi possível carregar os dados'
                state.status = 'ERROR'
            })
            .addCase(get.pending, (state, action) => {
                state.status = 'PENDING'
                state.selected = action.payload
            })
            .addCase(get.rejected, (state, action) => {
                state.status = 'ERROR'
                state.error = action.payload
            })
            .addCase(get.fulfilled, (state, action) => {
                state.status = 'SUCCESS'
                state.selected = action.payload
            })
            .addCase(add.fulfilled, (state, action) => {
                state.status = 'SAVE_SUCCESS'
                state.message = 'Salvo com Sucesso!'
            })
            .addCase(add.rejected, (state, action) => {
                state.status = 'SAVE_ERROR'
                state.message = action.payload.message || 'Não foi possível salvar'
            })
            .addCase(update.fulfilled, (state, action) => {
                state.status = 'UPDATED_SUCCESS'
                state.message = 'Atualizado com Sucesso!'
                state.selected = action.payload
            })
            .addCase(update.pending, (state) => {
                state.status = 'PENDING'
            })
            .addCase(update.rejected, (state, action) => {
                state.status = 'UPDATED_ERROR'
                state.message = action.payload.message || 'Não foi possível atualizar'
            })
            .addCase(archive.fulfilled, (state, action) => {
                state.status = 'ARCHIVED_SUCCESS'
            })
            .addCase(archive.rejected, (state, action) => {
                state.selected = action.error
                state.status = 'ARCHIVED_ERROR'
            })
            .addCase(unarchive.fulfilled, (state, action) => {
                state.status = 'UNARCHIVED_SUCCESS'
            })
            .addCase(unarchive.rejected, (state, action) => {
                state.selected = action.error
                state.status = 'UNARCHIVED_ERROR'
            })
    }
})

export const { clearSelected } = costCenterSlice.actions
export default costCenterSlice.reducer 